
/**
 * @roxi/routify 2.18.8
 * File generated Mon Aug 05 2024 14:11:42 GMT+0200 (Central European Summer Time)
 */

export const __version = "2.18.8"
export const __timestamp = "2024-08-05T12:11:42.658Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/admin/explorer",
          "id": "_admin_explorer",
          "component": () => import('../src/pages/admin/explorer.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/ffmpeg",
          "id": "_admin_ffmpeg",
          "component": () => import('../src/pages/admin/ffmpeg.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/history",
          "id": "_admin_history",
          "component": () => import('../src/pages/admin/history.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/admin/index",
          "id": "_admin_index",
          "component": () => import('../src/pages/admin/index.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/admin/templates",
          "id": "_admin_templates",
          "component": () => import('../src/pages/admin/templates.svelte').then(m => m.default)
        }
      ],
      "path": "/admin"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isFallback": true,
          "path": "/anon/_fallback",
          "id": "_anon__fallback",
          "component": () => import('../src/pages/anon/_fallback.svelte').then(m => m.default)
        }
      ],
      "path": "/anon"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/d/:key",
          "id": "_d__key",
          "component": () => import('../src/pages/d/[key].svelte').then(m => m.default)
        }
      ],
      "path": "/d"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/dall/:key",
          "id": "_dall__key",
          "component": () => import('../src/pages/dall/[key].svelte').then(m => m.default)
        }
      ],
      "path": "/dall"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/invite",
      "id": "_invite",
      "component": () => import('../src/pages/invite.svelte').then(m => m.default)
    },
    {
      "isPage": true,
      "path": "/login",
      "id": "_login",
      "component": () => import('../src/pages/login.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isFallback": true,
          "path": "/mydrive/_fallback",
          "id": "_mydrive__fallback",
          "component": () => import('../src/pages/mydrive/_fallback.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isFallback": true,
              "path": "/mydrive/received/_fallback",
              "id": "_mydrive_received__fallback",
              "component": () => import('../src/pages/mydrive/received/_fallback.svelte').then(m => m.default)
            }
          ],
          "path": "/mydrive/received"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isFallback": true,
              "path": "/mydrive/sent/_fallback",
              "id": "_mydrive_sent__fallback",
              "component": () => import('../src/pages/mydrive/sent/_fallback.svelte').then(m => m.default)
            }
          ],
          "path": "/mydrive/sent"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isFallback": true,
              "path": "/mydrive/trash/_fallback",
              "id": "_mydrive_trash__fallback",
              "component": () => import('../src/pages/mydrive/trash/_fallback.svelte').then(m => m.default)
            }
          ],
          "path": "/mydrive/trash"
        }
      ],
      "path": "/mydrive"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isFallback": true,
          "path": "/projects/_fallback",
          "id": "_projects__fallback",
          "component": () => import('../src/pages/projects/_fallback.svelte').then(m => m.default)
        }
      ],
      "path": "/projects"
    },
    {
      "isPage": true,
      "path": "/reset",
      "id": "_reset",
      "component": () => import('../src/pages/reset.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isFallback": true,
          "path": "/s/_fallback",
          "id": "_s__fallback",
          "component": () => import('../src/pages/s/_fallback.svelte').then(m => m.default)
        }
      ],
      "path": "/s"
    },
    {
      "isPage": true,
      "path": "/send",
      "id": "_send",
      "component": () => import('../src/pages/send.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [],
      "path": "/share"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/w/:key",
          "id": "_w__key",
          "component": () => import('../src/pages/w/[key].svelte').then(m => m.default)
        }
      ],
      "path": "/w"
    },
    {
      "isDir": true,
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/watch/index",
          "id": "_watch_index",
          "component": () => import('../src/pages/watch/index.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "isReset": true,
      "path": "/watch",
      "id": "_watch__reset",
      "component": () => import('../src/pages/watch/_reset.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/watchlive/index",
          "id": "_watchlive_index",
          "component": () => import('../src/pages/watchlive/index.svelte').then(m => m.default)
        }
      ],
      "path": "/watchlive"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

