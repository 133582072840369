<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";

  const config = {
      urlTransform: {
          apply: url => '/app'+url, //external URL
          remove: url => url.replace('/app', ''), //internal URL
      }


  }
</script>

<Router {routes} {config}/>