import "./app.postcss";
import "./global.css";
import HMR from "@roxi/routify/hmr";
import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

// Initialize the Sentry SDK here
/*
Sentry.init({
    dsn: "https://904d0e60f55c48fb9f8b179275b50eb8@o4504916038516736.ingest.sentry.io/4504916041990144",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
});
*/

const app = HMR(App, { target: document.body }, "routify-app");

export default app;
